export class WatteryResponse<T> {
  value: WatteryResponseData<T>;
  /**
   * @deprecated
   */
  success: boolean;
  private data?: T;
  public message?: string;
  public resultCode: WatteryResultCode;
  public requestId?: string;

  constructor(value: WatteryResponseData<T>, success: boolean) {
    this.value = value; //for grace period
    this.data = value.data;
    this.message = value.message;
    this.resultCode = value.resultCode;
    this.requestId = value.requestId;
    this.success = success; //for grace period
  }

  public isError() {
    return this.resultCode === WatteryResult.Error;
  }

  public isSuccess() {
    return this.resultCode === WatteryResult.Ok;
  }

  public isUnauthorized() {
    return this.resultCode === WatteryResult.Unauthorized;
  }

  public isNotFound() {
    return this.resultCode === WatteryResult.NotFound;
  }

  public isPermissionDenied() {
    return this.resultCode === WatteryResult.PermissionDenied;
  }

  /**
   * @deprecated use {@link response} instead
   * @returns the data contained in the response
   */
  public onSuccess(): T {
    return this.value.data!;
  }

  /**
   * @throws an error if `data` is `undefined` or `null`
   */
  public get response(): T {
    if (this.data === undefined || this.data === null) {
      throw new Error(
        "Can't unwrap a null|undefined response. Only use when 'isSuccess()' is true."
      );
    }
    return this.data;
  }

  /**
   * @deprecated use direct access variables [{@link message}, {@link resultCode} (or {@link isSuccess}, {@link isError}, {@link isUnauthorized}, {@link isPermissionDenied}, {@link isNotFound}) and {@link response}] instead
   * @returns all of the response
   */
  public onError(): WatteryResponseData<T> {
    return {
      data: this.value.data,
      message: this.value.message,
      resultCode: this.value.resultCode,
      requestId: this.value.requestId,
    };
  }
}

export declare interface WatteryResponseData<T> {
  data?: T;
  message: string;
  resultCode: WatteryResultCode;
  requestId?: string;
}

export const WatteryResult = {
  Ok: 10,
  Unauthorized: 20,
  NotFound: 30,
  PermissionDenied: 40,
  Error: 50,
} as const;

type WatteryResultCode = (typeof WatteryResult)[keyof typeof WatteryResult];
